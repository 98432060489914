import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService as AuthGuard } from './_shared/services/auth-guard.service';
import { HttpConfigInterceptor } from './_shared/http-config.interceptor';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION, } from 'ngx-ui-loader';
import { CommonModule, DatePipe } from '@angular/common';


const routes: Route[] = [
  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "registration-form",
    loadChildren: () => import('./registration-form/registration-form.module').then(m => m.RegistrationFormModule)
  },
  {
    path: "activate/:id",
    loadChildren: () => import('./activate/activate.module').then(m => m.ActivateModule)
  },
  {
    path: "**",
    loadChildren: () => import('./registration-form/registration-form.module').then(m => m.RegistrationFormModule)
  }
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,      
    NgbModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule, NgxUiLoaderHttpModule.forRoot({showForeground: true}),
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
