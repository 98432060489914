import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(public profileService: ProfileService, public router: Router) {}
  canActivate(): Observable<boolean> {
      return this.profileService.getProfile().pipe(map(profile => !isNullOrUndefined(profile)));
  }
}