import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Profile } from 'src/app/core/models/profile.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  
  constructor(private http: HttpClient) {}
  
  public getProfile(): Observable<Profile> {
    const profile = sessionStorage.getItem('profile');
    if (profile) {
        return of(JSON.parse(profile));
    } else {
        return this.http.get<Profile>(`/api/profile`).pipe(tap(newProfile => {
            sessionStorage.setItem('profile', JSON.stringify(newProfile));
        })); 
    }
  }
}