import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(this.handleError)
        );
            
    }

    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            window.location.href = '/sso/authorize';
        }
        return Observable.throw(err);
    }
}